import React from "react";
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

import socialBanner from "../images/og-image2.png";

const keywords = "covid travel app, travel, lockdown, quarantine, advisory, immigration rules, visa, sea, asia, southeast asia, covid19, covid-19, coronavirus, wfh, working from home, business, covid, coronavirus, flights, flying, airlines"

const SEO = ({ title, description, image, article }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={seo.title} htmlAttributes={{ lang : 'en' }}>
        <meta name="viewport" content="width=device-width, shrink-to-fit=no, initial-scale=1, maximum-scale=1, minimum-scale=1, viewport-fit=auto" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-title" content="Covid Travel" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={keywords} />
        <meta name="image" content={`${siteUrl}${socialBanner}`} />

        <meta property="og:type" content="website" />
        <meta property="og:image" content={`${siteUrl}${socialBanner}`} />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />

        <meta name="twitter:image" content={`${siteUrl}${socialBanner}`} />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={twitterUsername} />
    </Helmet>
  )
}

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: siteUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`
