import React from "react";
import { useSpring, animated as a } from "react-spring";
import MobileNav from "../Navigation";
import logo from "../../images/travel-logo.png";
import "./PageHeader.css";

const Header = ({ id, title, animTrigger }) => {

    const iconStyle = useSpring({
        width: animTrigger ? "40px" : "128px",
        marginTop: animTrigger ? "0px" : "200px",
        config: { mass: 1, tension: 600, friction: 20 }
    });

    // const target = usePortal(id);
    return (
        <nav className="page-header" data-uk-navbar>
            <MobileNav />
            <div className="uk-navbar-center uk-text-middle uk-text-center">
                <a.div style={iconStyle} className={`image-container`}>
                    <img
                        src={logo}
                        alt="Welcome to the Covid Travel App."
                        data-uk-img />
                </a.div>
            </div>
        </nav>
    )
}

export default Header;
