import React, { useState, useRef } from "react";
import { Link } from "gatsby";
import { animated, useTransition, useSpring, useChain, config } from "react-spring";

const data = [
      {
        name: "Discover",
        path: "/",
        css: "linear-gradient(135deg, #37eaff 0%, #00c8df 100%)",
        backgroundColor: "#00c8df",
        height: 400
    },
    {
        name: "About",
        path: "/about",
        css: "linear-gradient(135deg, #ffb555 0%, #ffa838 100%)",
        backgroundColor: "#ffa838",
        height: 200
    },
    {
        name: "Contact",
        path: "/contact",
        css: "linear-gradient(135deg, #ff99a4 0%, #ff7f8c 100%)",
        backgroundColor: "#ff7f8c",
        height: 400
    },
    ]

const MobileNav = () => {
  const [open, set] = useState(false)
  const springRef = useRef()
  const { height, width, borderRadius, background, ...rest } = useSpring({
    ref: springRef,
    config: config.stiff,
    from: {
        height: '60px',
        width: '60px',
        borderRadius: "100%",
        background: "rgb(205, 120, 248, 0)",
    },
    to: {
        width: open ? window.innerWidth + 'px' : '60px',
        height: open ? window.innerHeight + 'px' : '60px',
        borderRadius: open ? "0px" : "100%",
        background: open ? "rgb(255, 255, 255, 1)" : "rgb(205, 120, 248, 0)",
    }
  })

  const transRef = useRef()
  const transitions = useTransition(open ? data : [], item => item.name, {
    ref: transRef,
    trail: 200 / data.length,
    from: { opacity: 0, transform: 'scale(0)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0)' }
  })

  // useChain(open ? [springRef, transRef] : [transRef, springRef], [0, open ? 0.75 : 0.25])
  useChain(open ? [springRef, transRef] : [transRef, springRef], [0, open ? 0.1 : 0.6])

  const handleClick = e => {
      // console.log(e, e.target.innerText);
      set(open => !open)
  }
  return (
        <animated.div
            className="uk-hidden anim-nav-container"
            style={{
                ...rest,
                width: width,
                height: height,
                zIndex: "1000",
                background: background,
                borderRadius: borderRadius
            }}
            onClick={(e) => {
                handleClick(e)
            }}>
            <div className={`uk-margin-small-left uk-position-fixed uk-position-z-index`}>
                <span
                    className={`uk-hidden uk-float-left uk-light`}
                    data-uk-icon={`icon: ${!open ? "menu" : "arrow-left"}; ratio: 0.9`}
                />
                <div className="uk-flex uk-flex-row uk-flex-center">
                    <div className="col">
                        <div className={`nav-button con ${open && "open-nav"}`}>
                            <div className="bar arrow-top-r" />
                            <div className="bar arrow-middle-r" />
                            <div className="bar arrow-bottom-r" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="offcanvas-nav-collection">
            {transitions.map(({ item, key, props }) => (
                <Link key={key} style={{textDecoration: "none"}} to={item.path}>
                <animated.div
                    key={key}
                    className="anim-nav-item uk-flex-center uk-flex uk-flex-middle"
                    style={{ ...props, background: item.css }}>
                        <h3 style={{color: "white"}} className="uk-margin-remove uk-padding-remove">{item.name}</h3>
                </animated.div>
                </Link>
            ))}
            </div>
      </animated.div>
  )
}

export default MobileNav;
