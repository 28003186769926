import React, { useState, useEffect, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useSpring, animated as a } from "react-spring";
import SEO from "../components/seo";
import useIsIOS from "../components/Hooks/useIsIOS";
import Header from "../components/Header";
import {InstallPWA} from "../components/InstallPWA";
import bgimg from "../images/travel-bg.jpg";
import logo from "../images/travel-logo.png";
import "./layout.css";

const Layout = ({ location, children }) => {
    const { isIPhone, isIPad, isIOS, isSafari, prompt } = useIsIOS();
    const [preload, setPreload] = useState(true);
    const pwaRef = useRef(false);

    useEffect(
        () => {
            try {
                const UIkit = require("uikit/dist/js/uikit");
                const Icons = require("uikit/dist/js/uikit-icons");
                UIkit.use(Icons);
            } catch (e) {
                console.error("UIKit ERROR", e);
            }
        }, []
    )

    useEffect(
        () => {
            document.body.className += ` set-background`
            if (!navigator.standalone) {setPreload(false)};
            let timer1 = setTimeout(() => {
                setPreload(false);
            }, 1500)
            return () => {
                clearTimeout(timer1)
            }
        }, []
    )

    // useEffect(
    //     () => {
    //         heightRef.current = window.innerHeight;
    //         window.addEventListener("resize", function () {
    //             setPageHeight(window.innerHeight);
    //         });
    //     }, []
    // )


    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

    const coverScreenStyle = useSpring({
      width: preload ? "300%" : "0%",
      height: preload ? "300%" : "0%",
      opacity: preload ? "1" : "0",
      display: preload ? "flex" : "none",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translateY(-50%) translateX(-50%)",
      display: "flex",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1002,
      borderRadius: "100%",
      backgroundColor: "rgba(126, 87, 194, 1)",
      config: { mass: 1, tension: 600, friction: 60 }
    });

  return (
    <>
        <SEO />
        <a.div style={coverScreenStyle} />
        <main>
            {children}
            {prompt && <InstallPWA />}
        </main>
    </>
  )
}

export default Layout;
